import React, {useEffect, useState} from 'react'
import ContentLoader, {List} from 'react-content-loader'
import {getStickerInfo, stickerDownloadURL} from "./state/stickerPageActions"
import {TEST_STICKER_INFO} from "./state/testStickerInfo"
import './stickerPullPage.scss'
import FallBackImage from '../../assets/images/fallback_ipacket_folder.png'
import PrintIcon from '../../assets/icons/printicon.js'
import TextDocIcon from '../../assets/icons/textdocicon.js'
import SinglePgDocIcon from '../../assets/icons/singlepgdocicon.js'
import MultiPgDocIcon from '../../assets/icons/multipgdocicon.js'

export default function StickerPage(props){
  const urlParams = new URLSearchParams(window.location.search);
  const jwt = urlParams.get('jwt')
  const tk = urlParams.get('token')

  const token = ()=>{
    if(tk){return tk}
    return(jwt)
  }

  const {id} = props
  const [stickerInfo, setStickerInfo] = useState(null)
  const [loading, setLoading] = useState(true)
  const [errorLoading, setErrorLoading] = useState(false)
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const separateOptions = (options)=>{
    let separatedOptions = {
      standard_options:[],
      added_options:[],
    }
    for(let i = 0; i< options.length; i++){
      if(options[i].type === 'standard'){
        separatedOptions.standard_options.push(options[i])
      }
      else{
        separatedOptions.added_options.push(options[i])
      }
    }
    return separatedOptions
  }

  useEffect(()=>{
    getStickerInfo(id,token()).then((response)=>{
      // if(enableTest){
      //   return(TEST_STICKER_INFO[0])
      // }
      if(response.status===200){
          return response.json()
      }
      else{
        //TODO: Throw Sentry Error
        setErrorLoading(true)
        setLoading(false)
      }
    }).then(res=>{
      if(res){
        console.log({...res,...separateOptions(res.options)})
        setStickerInfo({...res,...separateOptions(res.options)})
      }
      setLoading(false)
    })
  },[])

  const togglePrintDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handlePrintButtonClick = (pageType) => {
    if(pageType == 'multi') {
      // Set the font size just a little smaller for multi page
      const fontSize = parseFloat(getComputedStyle(document.querySelector('.sticker-body')).fontSize);
      document.querySelector('.sticker-body').style.fontSize = `${fontSize * .85}px`;
      document.querySelector('.sticker-footer').style.fontSize = `${fontSize * .85}px`;

      window.print()

      // reset styles to initial
      document.querySelector('.sticker-body').style.fontSize = '13px';
      document.querySelector('.sticker-footer').style.fontSize = '.75em';

    } else if (pageType == 'single') {
      // check for height of document and if needing more columns in Added Options section
      const checkPrintMedia = () => {
          const stickerBodyContentHeight = document.querySelector('.sticker-added-options').offsetHeight + document.querySelector('.sticker-footer').offsetHeight;
          const contentDetailsPrintAreaHeight = 600 - document.querySelector('.sticker-header').offsetHeight - 10

          if (stickerBodyContentHeight > contentDetailsPrintAreaHeight) {
            // Content exceeds one page, adjust into 2 columns
            document.querySelector('.sticker-added-options-item-detail').style.columnCount = '2';
            // Now check if it needs to be 3 columns instead of 2
            if (!stickerInfo.standard_options) {
              document.querySelector('.sticker-added-options').classList.add('third-column')
              if(document.querySelector('.third-column').offsetHeight + document.querySelector('.sticker-footer').offsetHeight > contentDetailsPrintAreaHeight) {
                document.querySelector('.sticker-added-options-item-detail').style.columnCount = '3';
              }
            }
          }
      };

      const scaleFontSizeForPrinting = () => {
        const pageSize = {
          width: 800,
          height: 600,
        };

        // Calculate the scale factor for font size
        const scaleFactor = Math.min(
          pageSize.width / document.body.clientWidth,
          pageSize.height / document.body.clientHeight
        );

        // Set the font size dynamically
        const fontSize = parseFloat(getComputedStyle(document.querySelector('.sticker-body')).fontSize);
        document.querySelector('.sticker-body').style.fontSize = `${fontSize * scaleFactor}px`;
        document.querySelector('.sticker-footer').style.fontSize = `${fontSize * scaleFactor}px`;
      };

      // Call fxn from above to change content to columns if longer than one page
      checkPrintMedia();
      // Call fxn from above to alter font size if needed
      scaleFontSizeForPrinting();

      window.print()

      // reset styles to initial
      document.querySelector('.sticker-body').style.fontSize = '13px';
      document.querySelector('.sticker-footer').style.fontSize = '.75em';
      document.querySelector('.sticker-added-options-item-detail').style.columnCount = '1';
    }
  };

  const handleDownloadTextOnlyDoc = () => {
    window.open(stickerDownloadURL(id,token()), '_blank');
  };

  const VehicleDetailsItem = (props)=>{
    return(
        <div className="sticker-vehicle-details-item">
          <div className="sticker-vehicle-details-item-title">
            {props.title}
          </div>
          <div className="sticker-vehicle-details-item-info">
            {props.detail}
          </div>
        </div>
    )
  }
  const Sticker = ()=>{
    let enNumFormatter = Intl.NumberFormat('en-US')
    const skinStyleString = `
      body .sticker-header .sticker-suggested-price-container .sticker-suggested-price{
        background-color: ${stickerInfo.metadata.primary_make_color};
        color: ${stickerInfo.metadata.secondary_make_color};
      }
      .sticker-header .sticker-suggested-price-container .sticker-suggested-price:before{
        border-color: transparent transparent ${stickerInfo.metadata.primary_make_color};
      }
      body .sticker-body .sticker-added-options-container .sticker-added-options{
        background-color: ${stickerInfo.metadata.primary_make_color+'1A'};
      }
      body .sticker-page-controls .sticker-page-controls-print:hover{
        background-color: ${stickerInfo.metadata.primary_make_color};
      }
      .sticker-page-controls .sticker-page-controls-print:hover svg{
        fill: ${stickerInfo.metadata.secondary_make_color};
      }
    `
    return(
      <div className='sticker-container'>
        <style>
          {skinStyleString}
        </style>
        <div className="sticker">
          <div className="sticker-header">
            <div className="sticker-logo-container">
              {stickerInfo.generic_details.logo?
                <img className="sticker-logo" src={stickerInfo.generic_details.logo}/>
                :
                <img className="sticker-logo" src={FallBackImage}/>
              }
            </div>
            <div className="sticker-vehicle-details-container">
              <div className="sticker-vehicle-details">
                <div className="sticker-vehicle-details-ymm">
                  {`${stickerInfo.generic_details.year} ${stickerInfo.generic_details.make} ${stickerInfo.generic_details.model?stickerInfo.generic_details.model:""}`}
                </div>
                <div className="sticker-vehicle-details-items">
                  {
                    stickerInfo.generic_details.vin?
                    <VehicleDetailsItem title="VIN" detail={stickerInfo.generic_details.vin}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.generic_details.trim?
                    <VehicleDetailsItem title="TRIM" detail={stickerInfo.generic_details.trim}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.generic_details.engine?
                    <VehicleDetailsItem title="ENGINE" detail={stickerInfo.generic_details.engine}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.generic_details.transmission?
                    <VehicleDetailsItem title="TRANSMISSION" detail={stickerInfo.generic_details.transmission}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.generic_details.drivetrain?
                    <VehicleDetailsItem title="DRIVETRAIN" detail={stickerInfo.generic_details.drivetrain}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.generic_details.interior_color?
                    <VehicleDetailsItem title="INTERIOR" detail={stickerInfo.generic_details.interior_color}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.generic_details.exterior_color?
                    <VehicleDetailsItem title="EXTERIOR" detail={stickerInfo.generic_details.exterior_color}/>
                    :
                    <React.Fragment/>
                  }
                </div>
              </div>
            </div>
            {(stickerInfo.pricing_details &&  stickerInfo.pricing_details.total) ?
              <div className="sticker-suggested-price-container">
                <div className="sticker-suggested-price-label">
                  {`TOTAL ${(stickerInfo.metadata && stickerInfo.metadata.oem_quality)?"SUGGESTED":"PREDICTED"} PRICE`}
                </div>
                <div className="sticker-suggested-price">
                  {`$${enNumFormatter.format(stickerInfo.pricing_details.total)}`}
                </div>
              </div>
              :
              <React.Fragment/>
            }
          </div>
          <div className="sticker-body">
            {stickerInfo.standard_options && stickerInfo.standard_options.length ?
              <div className="sticker-standard-options-container">
                <div className="sticker-standard-options">
                  <div className="sticker-standard-options-title">
                    STANDARD OPTIONS
                  </div>
                  <ul className="sticker-standard-options-items">
                    {
                      stickerInfo.standard_options.map(option=>{
                        return(
                          <li className="sticker-standard-options-item" key={option.description}>
                              {`${option.code?option.code+' - ':''}${option.description?option.description:""}`}

                              <ul>
                                {option.sub_options && options.sub_options.map(sub_option=>{
                                  return(
                                    <li key={sub_option.description}>{`${sub_option.code?sub_option.code+' - ':''}${sub_option.description?sub_option.description:""}`}</li>
                                  )
                                })}
                              </ul>
                          </li>
                        )
                      })
                    }
                  </ul>

                </div>
              </div>
              :
              <React.Fragment/>
            }

            <div className="sticker-added-options-container">
              <div className="sticker-added-options">
                {stickerInfo.pricing_details && (stickerInfo.pricing_details.base) ?
                  <div className="sticker-added-options-base-suggested-price">
                    <div className="sticker-added-options-base-suggested-price-label">
                      BASE SUGGESTED PRICE
                    </div>
                    <div className="sticker-added-options-base-suggested-price-value">
                      {`$${enNumFormatter.format(stickerInfo.pricing_details.base)}`}
                    </div>
                  </div>
                  :<React.Fragment/>
                }
                <div className="sticker-added-options-items">
                  <div className="sticker-added-options-item">
                    <div className="sticker-added-options-item-label">
                      ADDED OPTIONS
                    </div>
                    <div className="sticker-added-options-item-detail">
                      <ul>
                        {
                          stickerInfo.added_options.map(option=>{
                            return(

                                  <li className={`sticker-added-options-item-detail-li${option.price?" sticker-added-options-item-detail-li-has-price":""}`}key={option.description}>
                                    <div className="sticker-added-options-item-detail-desc">{`${option.code?option.code+' - ':''}${option.description?option.description:""}`}</div>
                                    <div className="sticker-added-options-item-detail-price">
                                      {option.price?
                                        <>{`${(Math.sign(option.price)<0) ? '-':''}$${enNumFormatter.format(Math.abs(option.price))}`}</>
                                        :
                                        <React.Fragment/>
                                      }
                                    </div>
                                    {(option.sub_options && option.sub_options.length)?
                                        <ul>
                                          {option.sub_options.map(sub_option=>{
                                            return(
                                              <li>
                                                <div className="sticker-added-options-item-detail-desc">{`${sub_option.code?sub_option.code + ' - ':''}${sub_option.description?sub_option.description:""}`}</div>
                                                {sub_option.price?
                                                  <div className="sticker-added-options-item-detail-price">{`${(Math.sign(sub_option.price)<0) ? '-':''}$${enNumFormatter.format(Math.abs(sub_option.price))}`}</div>
                                                  :
                                                  <React.Fragment/>
                                                }
                                              </li>
                                            )
                                          })}
                                        </ul>
                                      :
                                      <React.Fragment/>
                                    }
                                  </li>

                            )
                          })
                        }
                    </ul>
                  </div>
                  </div>
                </div>
                {stickerInfo.pricing_details && (stickerInfo.pricing_details.base || stickerInfo.pricing_details.total || stickerInfo.pricing_details.details)?
                  <div className="sticker-added-options-price-totals">
                    <div className="sticker-added-options-price-totals-label">
                      PRICE DETAILS
                    </div>
                    {(stickerInfo.pricing_details && stickerInfo.pricing_details.details)?
                      <div className="sticker-added-options-price-totals-items">
                        {stickerInfo.pricing_details.details.map(detail=>{
                          return(
                            <div className="sticker-added-options-price-totals-item" key={detail.label}>
                              <div className="sticker-added-options-price-totals-item-label">
                                {detail.label}
                              </div>
                              <div className="sticker-added-options-price-totals-item-price">
                                {`${(Math.sign(detail.price)<0) ? '-':''}$${enNumFormatter.format(Math.abs(detail.price))}`}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      :
                      <React.Fragment/>
                    }
                    <div className="sticker-added-options-total-suggested-price">
                      <div className="sticker-added-options-total-suggested-price-label">
                        {`TOTAL ${(stickerInfo.metadata && stickerInfo.metadata.oem_quality)?"SUGGESTED":"PREDICTED"} PRICE`}
                      </div>
                      <div className="sticker-added-options-total-suggested-price-value">
                        {`$${enNumFormatter.format(stickerInfo.pricing_details.total)}`}
                      </div>
                    </div>
                  </div>
                  :
                  <React.Fragment/>
                }
              </div>
              </div>

          </div>
          <div className="sticker-footer">
            {stickerInfo.metadata && stickerInfo.metadata.disclaimer}
          </div>
        {!(stickerInfo.metadata && stickerInfo.metadata.oem_quality)?
          <div className="sticker-overlay">
            PREDICTIVE DATA
          </div>
          :
          <React.Fragment/>
        }
        </div>
      </div>
    )
  }
  return(
    <div className="sticker-page-container">
      <div className="sticker-page">

        {(stickerInfo && !loading)?
          <>
            <div className="sticker-page-controls">
              <button onClick={togglePrintDropdown}>
              <PrintIcon/>
              </button>
              <div className="print-dropdown-container">
                <div className={`print-dropdown-content ${isDropdownOpen ? 'print-dropdown-visible' : ''}`}>
                  <button onClick={() => handlePrintButtonClick('single')}><SinglePgDocIcon /><span>Single Page</span></button>
                  <button onClick={() => handlePrintButtonClick('multi')}><MultiPgDocIcon /><span>Multi Page</span></button>
                  <button onClick={() => handleDownloadTextOnlyDoc()}><TextDocIcon /><span>Text Only</span></button>
                </div>
              </div>
            </div>
            <Sticker/>
          </>
          :
          <>
            {errorLoading?
              <div className="sticker-page-error">
                There was a problem loading this document
              </div>
              :
              <div className="sticker-page-loading">
                <div className='sticker-page-loading-section1'>
                  <ContentLoader
                    height={200}
                    width={250}
                  >
                    <rect x="40" y="40" rx="3" ry="3" width="150" height="150" />
                  </ContentLoader>
                  <ContentLoader
                    height={120}
                    width={250}
                  >
                    <rect x="20" y="40" rx="3" ry="3" width="200" height="20" />
                    <rect x="40" y="78" rx="3" ry="3" width="150" height="10" />
                    <rect x="60" y="96" rx="3" ry="3" width="100" height="10" />
                  </ContentLoader>
                </div>
                <div className='sticker-page-loading-line'>
                  <ContentLoader
                    width={1000}
                    height={1}
                  >
                    <rect x="120" y="0" rx="3" ry="3" width="700" height="1" />
                  </ContentLoader>
                </div>
                <div>
                  <ContentLoader
                    height={180}
                    width={400}
                  >
                        <rect x="20" y="10" rx="3" ry="3" width="20" height="10" />
                        <rect x="60" y="10" rx="3" ry="3" width="200" height="10" />
                        <rect x="280" y="10" rx="3" ry="3" width="20" height="10" />
                        <rect x="60" y="30" rx="3" ry="3" width="170" height="10" />
                        <rect x="105" y="50" rx="3" ry="3" width="150" height="10" />
                        <rect x="20" y="70" rx="3" ry="3" width="20" height="10" />
                        <rect x="60" y="70" rx="3" ry="3" width="180" height="10" />
                        <rect x="280" y="70" rx="3" ry="3" width="20" height="10" />
                        <rect x="60" y="90" rx="3" ry="3" width="200" height="10" />
                        <rect x="60" y="110" rx="3" ry="3" width="170" height="10" />
                        <rect x="105" y="130" rx="3" ry="3" width="150" height="10" />
                        <rect x="20" y="150" rx="3" ry="3" width="20" height="10" />
                        <rect x="60" y="150" rx="3" ry="3" width="180" height="10" />
                        <rect x="280" y="150" rx="3" ry="3" width="20" height="10" />
                        <rect x="120" y="170" rx="3" ry="3" width="100" height="10" />
                        <rect x="120" y="190" rx="3" ry="3" width="120" height="10" />
                  </ContentLoader>
                </div>
                <div className='sticker-page-loading-line'>
                  <ContentLoader
                        width={1000}
                        height={1}
                      >
                        <rect x="120" y="0" rx="3" ry="3" width="700" height="1" />
                      </ContentLoader>
                </div>
                <div>
                  <div className='sticker-page-loading-section3'>
                      <ContentLoader
                        width={400}
                        height={300}
                      >
                        <rect x="20" y="20" rx="3" ry="3" width="200" height="15" />
                        <rect x="20" y="40" rx="3" ry="3" width="100" height="12" />
                          {/**Left */}
                        <rect x="40" y="65" rx="3" ry="3" width="20" height="10" />
                        <rect x="80" y="65" rx="3" ry="3" width="200" height="10" />
                        <rect x="40" y="85" rx="3" ry="3" width="20" height="10" />
                        <rect x="82" y="85" rx="3" ry="3" width="170" height="10" />
                        <rect x="40" y="105" rx="3" ry="3" width="190" height="10" />

                        <rect x="40" y="125" rx="3" ry="3" width="20" height="10" />
                        <rect x="80" y="125" rx="3" ry="3" width="200" height="10" />
                        <rect x="40" y="145" rx="3" ry="3" width="20" height="10" />
                        <rect x="82" y="145" rx="3" ry="3" width="170" height="10" />
                        <rect x="40" y="165" rx="3" ry="3" width="190" height="10" />

                        <rect x="40" y="185" rx="3" ry="3" width="20" height="10" />
                        <rect x="80" y="185" rx="3" ry="3" width="200" height="10" />
                        <rect x="40" y="205" rx="3" ry="3" width="20" height="10" />
                        <rect x="82" y="205" rx="3" ry="3" width="170" height="10" />
                        <rect x="40" y="225" rx="3" ry="3" width="190" height="10" />

                        <rect x="40" y="245" rx="3" ry="3" width="20" height="10" />
                        <rect x="80" y="245" rx="3" ry="3" width="200" height="10" />
                        <rect x="40" y="265" rx="3" ry="3" width="20" height="10" />
                        <rect x="80" y="265" rx="3" ry="3" width="170" height="10" />

                      </ContentLoader>
                    </div>
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}
